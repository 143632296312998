import { Link } from './../util/router';
import { useAuth } from './../util/auth';
import { MagnifyingGlassIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { useRouter } from './../util/router';
import { useMedia } from 'react-use';
import { useTextSearchStore } from '../stores';
import { getProfilePhoto } from '../util/util';
import React, { useState, useCallback, useEffect } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import wordmark from '../assets/images/wordmark.png';

const Navbar = () => {
  const auth = useAuth();
  const { location } = useRouter();
  const isWide = useMedia('(min-width: 480px)');
  const { isInputOpen, setIsInputOpen } = useTextSearchStore();
  const [userPhoto, setUserPhoto] = useState();

  const navigation = [
    { name: 'My Lists', href: '/my-lists' },
    { name: 'My Profile', href: `/profile/${auth.user?.username}` },
    { name: 'Account Settings', href: '/settings/general' },
    { name: 'About', href: '/about' },
    //{ name: 'Pricing', href: '/pricing' },
  ];

  const fetchProfilePhoto = useCallback(async () => {
    try {
      const userData = auth.user;
      if (userData) {
        const photo = await getProfilePhoto(userData.id);
        setUserPhoto(photo);
      }
    } catch (error) {
      console.error(error);
    }
  }, [auth.user]);

  useEffect(() => {
    fetchProfilePhoto();
  }, [fetchProfilePhoto]);

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav className="mx-auto flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link
            to="/"
            className="Showrunner text-secondary-red text-2xl font-medium uppercase leading-none"
          >
            {wordmark && <img src={wordmark} alt="_" className="w-48" />}
          </Link>
        </div>
        <div className="flex">
          {location.pathname === '/' &&
            !isWide &&
            (isInputOpen ? (
              <FunnelIcon
                className="stroke-2 text-secondary-red h-5 w-5 mr-2 my-auto"
                aria-hidden="true"
                onClick={() => setIsInputOpen(false)}
              />
            ) : (
              <MagnifyingGlassIcon
                className="stroke-2 text-secondary-red h-5 w-5 mr-2 my-auto"
                aria-hidden="true"
                onClick={() => setIsInputOpen(true)}
              />
            ))}
          <div className="dropdown dropdown-end">
            <div tabIndex={0} role="button">
              <div className="avatar">
                <div className="w-8 rounded-full">
                  {userPhoto ? <img src={userPhoto} alt="" /> : <UserCircleIcon />}
                </div>
              </div>
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content menu bg-white rounded-box z-[1] w-52 p-2 shadow text-[0.9rem]"
            >
              {auth.user && (
                <li className="py-1">
                  <p className="font-semibold ">Hi {auth.user.name}</p>
                </li>
              )}
              {auth.user ? (
                navigation.map(item => (
                  <li key={item.name}>
                    <Link to={item.href}>{item.name}</Link>
                  </li>
                ))
              ) : (
                <li key={'About'}>
                  <Link to={'/about'}>{'About'}</Link>
                </li>
              )}
              {!auth.user ? (
                <li className="py-1">
                  <Link to="/auth/signin" className="font-semibold text-gray-900">
                    Sign in <span aria-hidden="true">&rarr;</span>
                  </Link>
                </li>
              ) : (
                <li className="py-1">
                  <Link
                    to="auth/signout"
                    className="font-semibold text-secondary-red"
                    onClick={e => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign out <span aria-hidden="true">&rarr;</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
