import React, { useState, useEffect, useCallback, Fragment } from 'react';
import Meta from '../components/Meta';
import { Link } from '../util/router';
import { getFeaturedLists } from './../util/util';
import IMG_PLACEHOLDER from '../assets/images/placeholder1.png';
import ProfilePhoto from '../components/ProfilePhoto';

function FeaturedListsPage() {
  const [featuredLists, setFeaturedLists] = useState();
  const fetchFeaturedListsAndUsers = useCallback(async () => {
    try {
      const lists = await getFeaturedLists();
      console.log(lists);
      setFeaturedLists(lists);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchFeaturedListsAndUsers();
  }, [fetchFeaturedListsAndUsers]);

  return (
    <Fragment>
      <Meta title="Featured Lists" />
      <section className="w-screen h-[150px] overflow-hidden bg-white">
        <div className="flex w-full h-full max-w-[90rem] p-4 md:p-8 my-auto mx-auto">
          <div className="content-end">
            <h1 className="text-3xl md:text-3xl text-slate-600">Featured Lists</h1>
          </div>
        </div>
      </section>
      <section className="w-screen min-h-[calc(100vh-190px)]">
        <div className="flex w-full max-w-[90rem] p-3 md:p-8 justify-center mx-auto">
          {featuredLists?.length && (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-5 md:gap-8 w-full">
              {featuredLists.map(list => (
                <div
                  key={list.id}
                  className="relative w-full min-h-[260px] md:min-h-[290px] max-w-[11rem] md:max-w-[19rem] cursor-pointer justify-self-center"
                >
                  <Link href="/lists/[id]" to={`/lists/${list.id}`}>
                    <div className="w-auto h-4/5 rounded-lg shadow-xl overflow-hidden relative">
                      <img
                        className="absolute top-0 left-0 w-full h-full object-cover"
                        alt="list_image"
                        src={list.cover_photo ? list.cover_photo : IMG_PLACEHOLDER}
                      />
                    </div>
                  </Link>

                  <div className="w-full h-1/5">
                    <div className="mt-2 text-lg md:text-xl text-slate-900 uppercase whitespace-nowrap overflow-hidden text-ellipsis">
                      {list.list_name}
                    </div>
                    <div className="flex gap-2 justify-start">
                      <ProfilePhoto userId={list.owner} isSettings={false} small />
                      <div className="mt-2 text-sm text-gray-600">
                        {list?.username?.length ? `@${list.username}` : 'Owner: Unknown'}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </Fragment>
  );
}

export default FeaturedListsPage;
