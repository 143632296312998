import React, { useState, useCallback, useEffect } from 'react';
import { useAuth } from './../util/auth';
import { getListsByUsername } from '../util/util';
import { Link, useRouter } from '../util/router';
import { formatJoinDate } from '../util/data-factory';
import ProfilePhoto from './ProfilePhoto';
import IMG_PLACEHOLDER from '../assets/images/placeholder1.png';

function Profile(props) {
  const auth = useAuth();
  const router = useRouter();
  const { username } = router.query;
  const [userData, setUserData] = useState({});
  const [userLists, setUserLists] = useState([]);

  const fetchUserLists = useCallback(async () => {
    try {
      if (auth.user) {
        const { userData, userLists } = await getListsByUsername(username);
        const publicLists = userLists.filter(list => !list.is_private);
        setUserData(userData);
        setUserLists(publicLists);
      }
    } catch (error) {
      console.error(error);
    }
  }, [auth.user, username]);

  useEffect(() => {
    fetchUserLists();
  }, [auth.user, fetchUserLists]);

  return (
    <div className="w-full mt-32 px-3">
      <div className="max-w-[90rem] my-4 md:mt-8 mx-auto">
        <div className="flex flex-col md:flex-row w-full justify-center align-middle">
          <div className="w-full md:w-1/3 flex-none ">
            <ProfilePhoto userId={userData.id} isSettings={false} />
          </div>

          <div className="flex flex-col flex-1 w-full ml-3 md:ml-0 md:w-2/3 my-auto">
            <div className="text-lg md:text-3xl font-semibold text-secondary-red mt-2">
              @{userData.username}
            </div>
            <div className="mt-1 md:mt-2 text-lg md:text-2xl text-black/60">{userData.name}</div>
            <div className="mt-1 md:mt-2 text-sm md:text-lg text-black/90">{userData.about}</div>
            <div className="mt-1 md:mt-2 flex">
              <strong>{userLists.length}</strong>
              <p className="text-black/60 mx-2">Curated public lists</p>
              <p className="ml-4">Joined </p>
              <p className="text-black/60 mx-1">{formatJoinDate(userData.created_at)}</p>
            </div>
          </div>
        </div>
      </div>

      <section className="min-h-[calc(100vh-190px)] mt-3 bg-white">
        <div className="flex w-full max-w-[90rem] p-3 md:p-8 justify-start mx-auto border-t border-t-gray">
          <div className="ml-2 mt-2 text-lg md:text-2xl font-semibold">Curated Lists</div>
        </div>
        <div className="flex w-full max-w-[90rem] p-1 md:p-8 justify-center mx-auto">
          {userLists.length ? (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 w-full">
              {userLists
                .filter(list => !list.is_private)
                .map(list => (
                  <div
                    key={list.id}
                    className="relative w-full min-h-[260px] md:min-h-[290px] max-w-[10rem] md:max-w-[19rem] cursor-pointer justify-self-center"
                  >
                    <Link href="/lists/[id]" to={`/lists/${list.id}`}>
                      <div className="w-auto h-4/5 rounded-lg shadow-xl overflow-hidden relative">
                        <img
                          className="absolute top-0 left-0 w-full h-full object-cover"
                          alt="list_image"
                          src={
                            list.first_show_data?.image_urls[0]
                              ? list.first_show_data.image_urls[0]
                              : IMG_PLACEHOLDER
                          }
                        />
                      </div>
                    </Link>

                    <div className="w-full h-1/5">
                      <div className="mt-2 text-lg md:text-xl text-slate-900 uppercase">
                        {list.list_name.length > 18
                          ? `${list.list_name.substring(0, 18)}...`
                          : list.list_name}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className="text-center text-lg md:text-xl text-slate-900 mt-10 ">
              Couldn't find any lists.
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default Profile;
