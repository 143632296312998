import { useEffect, useState, useCallback, Fragment } from 'react';
import { useRouter, Link } from '../util/router';
import {
  MapPinIcon,
  BoltIcon,
  CalendarIcon,
  TrashIcon,
  ArrowUpOnSquareIcon,
} from '@heroicons/react/24/outline';
import Meta from '../components/Meta';
import ShowrunnerMap from '../components/ShowRunnerMap';

import { getListById, getUsernameByList } from '../util/util';
import { removeShowFromList } from '../util/db';
import {
  formatDate,
  trendingShowsLayer,
  regularShowsLayer,
  isPastShow,
} from '../util/data-factory';
import { useWebShare } from '../hooks';
import { useAuth } from '../util/auth';
import { useMedia } from 'react-use';
import { useMapViewStore } from '../stores';
import ProfilePhoto from '../components/ProfilePhoto';

const SingleListPage = () => {
  const router = useRouter();
  const auth = useAuth();
  const { id } = router.query;
  const { shareContent } = useWebShare();
  const isWide = useMedia('(min-width: 480px)');
  const { listMapViewState, setListMapViewState } = useMapViewStore();

  const [isFetching, setIsFetching] = useState(true);
  const [list, setList] = useState();
  const [featureData, setFeatureData] = useState();
  const [showId, setShowId] = useState();
  const [username, setUsername] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [showMobileMap, setShowMobileMap] = useState(false);
  const [userId, setUserId] = useState();

  const [mapPopupInfo, setMapPopupInfo] = useState();
  const [mapImageInfo, setMapImageInfo] = useState();
  const [mapTopTagsInfo, setMapTopTagsInfo] = useState();

  /**
   * API call to get list by ID
   */
  const fetchListByID = useCallback(async () => {
    try {
      setIsFetching(true);
      if (id) {
        const list = await getListById(id);
        const userId = list.owner;
        const username = await getUsernameByList(userId);

        setList(list);
        setUserId(userId);
        setUsername(username);

        if (userId === auth.user.id) {
          setIsOwner(true);
        }
      }
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  }, [id, auth.user]);

  useEffect(() => {
    fetchListByID();
  }, [id, fetchListByID]);

  /**
   * API call to remove show from list
   * @param {string} listId
   * @param {number | string} showId
   */
  const removeShow = async (listId, showId) => {
    try {
      await removeShowFromList(listId, showId);
      fetchListByID();
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Format filtered data as features to be
   * read as clusters by map-gl. Updates when filtered
   * data changes.
   */
  useEffect(() => {
    if (!list) return;

    const formatted_data = list.shows_data.map(i => {
      let longitude = parseFloat(i.venues.longitude);
      let latitude = parseFloat(i.venues.latitude);
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
        properties: { ...i, lat: latitude, lng: longitude },
      };
    });

    if (!formatted_data) return;
    setFeatureData({ type: 'FeatureCollection', features: formatted_data });
  }, [list]);

  /**
   * Handles logic when click event on markers
   */
  const onPinClick = useCallback(event => {
    const feature = event.features && event.features[0];
    if (!feature) return;
    const layer = feature.layer;

    if (layer.id === trendingShowsLayer.id || layer.id === regularShowsLayer.id) {
      //const mapboxSource = mapRef.current.getSource('my-data');
      //console.debug(mapboxSource);
      event.originalEvent.stopPropagation();
      setShowId(feature.properties.show_id);
      //setMapImageInfo(JSON.parse(feature.properties.image_urls));
      setMapPopupInfo({ ...feature.properties, venues: JSON.parse(feature.properties.venues) });
      setMapImageInfo(JSON.parse(feature.properties.image_urls));
      setMapTopTagsInfo(JSON.parse(feature.properties.top_tags));
    } else {
      event.originalEvent.stopPropagation();
    }
  }, []);

  /**
   * Handles logic when moving on the map, triggers on
   * zooming and panning.
   */
  const onMove = useCallback(
    event => {
      const { latitude, longitude, zoom } = event.viewState;
      setListMapViewState({ latitude, longitude, zoom });
    },
    [setListMapViewState]
  );

  /**
   * Handles logic when share button is clicked
   */
  const handleShareClick = () => {
    shareContent({
      files: null,
      title: list?.list_name ?? 'Showrunner',
      text: 'Check out this list I created with my favorite shows and galleries',
      url: window.location.href,
    });
  };

  const closeShowMapDialog = () => {
    setMapPopupInfo(null);
    setMapImageInfo(null);
    setMapTopTagsInfo(null);
  };

  if (isFetching) {
    return (
      <MessageFragment
        meta="Single List"
        message={
          <span className="loading loading-spinner loading-lg text-secondary-red mx-auto"></span>
        }
      />
    );
  }

  return (
    <Fragment>
      <Meta title="Single List" />
      <section className="w-screen h-[100px] overflow-hidden bg-white"></section>
      {/* Show Map/List Button */}
      {!isWide && (
        <div className="absolute top-[calc(100vh-110px)] left-[calc(50vw-80px)] w-40 text-center z-20">
          <button
            className="btn sm:btn-sm md:btn-md bg-secondary-red rounded-3xl"
            onClick={() => setShowMobileMap(!showMobileMap)}
          >
            {showMobileMap ? 'Show List' : 'Show Map'}
          </button>
        </div>
      )}
      <div className="flex flex-col sm:flex-row w-screen mt-2 h-[calc(100vh-100px)] overflow-auto sm:overflow-hidden">
        <div className="w-full sm:w-[50%] max-w-[90rem] md:p-4 justify-center mx-auto">
          <div className="flex w-full py-2 px-3 my-auto mx-auto justify-between">
            <h1 className="uppercase text-lg md:text-2xl font-medium opacity-70 mx-2">
              {list?.list_name ?? ''}
            </h1>
            <div className="flex align-middle my-auto">
              <button className="btn btn-sm mx-2 my-auto" onClick={handleShareClick}>
                <ArrowUpOnSquareIcon className="w-4" />
              </button>
            </div>
          </div>
          <div className="">
            <Link to={`/profile/${username}`} className="flex gap-2 justify-start ml-4">
              <ProfilePhoto userId={userId} isSettings={false} small />
              <div className="mt-2 text-sm text-gray-600">{`@${username}`}</div>
            </Link>
            {list?.description && <div className="p-2 max-w-2">list?.description</div>}
          </div>

          {/* Mobile List */}
          {!showMobileMap && !isWide && (
            <div className={`flex flex-col max-h-full overflow-y-auto px-1`}>
              {list &&
                list.shows_data.map(show => (
                  <div
                    key={show.show_id}
                    className={`relative p-3 bg-gray-100 uppercase font-medium text-black leading-10 border-b ${
                      showId === show.show_id
                        ? 'border-4 border-b-4 bg-white rounded-lg border-regal-blue'
                        : ''
                    }`}
                  >
                    {isPastShow(show.end_date) && (
                      <div className="absolute top-2 right-3 z-10">
                        <div className="badge badge-sm normal-case">Past Show</div>
                      </div>
                    )}
                    {show?.image_urls[0] && (
                      <Link href="/show/[id]" to={`/show/${show.show_id}`}>
                        <div className="relative md:max-h-[390px] mb-1 md:mb-5 overflow-hidden content-end cursor-pointer">
                          {show.recommended && (
                            <div className="absolute top-2 left-2 py-0 px-2 bg-off-white flex items-center gap-1">
                              <BoltIcon className="w-5" />
                              <small>Trending</small>
                            </div>
                          )}
                          <img
                            className="min-w-full m-auto"
                            src={show.image_urls[0]}
                            alt="showrunner"
                          />
                        </div>
                      </Link>
                    )}
                    <Link href="/show/[id]" to={`/show/${show.show_id}`}>
                      <h1 className="text-md md:text-2xl my-1">{show.display_title}</h1>
                    </Link>
                    <p className="text-secondary-red text-xs md:text-[1rem] leading-normal">
                      {show.top_tags.join(' | ')}
                    </p>

                    <div className="flex justify-between text-xs md:text-sm my-2 sm:my-4 items-center font-normal">
                      <div className="flex flex-1 flex-wrap capitalize">
                        <div className="flex mr-4 py-1 items-center">
                          <MapPinIcon className="w-5" /> {show.venues?.venue_name}
                        </div>
                        <div className="flex mr-4 py-1 items-center">
                          <CalendarIcon className="w-5 mr-1" /> {formatDate(show.start_date, true)}{' '}
                          - {formatDate(show.end_date, true)}
                        </div>
                      </div>
                      {isOwner && (
                        <div
                          className="flex-none cursor-pointer items-center"
                          onClick={() => removeShow(list.id, show.show_id)}
                        >
                          <TrashIcon className="w-5 text-dark-red" />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}

          {/* Desktop List */}
          {isWide && (
            <div
              className={`grid grid-cols-2 grid-flow-row-dense gap-4 h-[90%] overflow-auto px-1`}
            >
              {list &&
                list.shows_data.map(show => (
                  <div
                    key={show.show_id}
                    className={`relative p-3 bg-gray-100 uppercase font-medium text-black leading-10 border-b ${
                      showId === show.show_id
                        ? 'border-4 border-b-4 bg-white rounded-lg border-regal-blue'
                        : ''
                    }`}
                  >
                    {isPastShow(show.end_date) && (
                      <div className="absolute top-2 right-3 z-10">
                        <div className="badge badge-sm normal-case">Past Show</div>
                      </div>
                    )}
                    {show?.image_urls[0] && (
                      <Link href="/show/[id]" to={`/show/${show.show_id}`}>
                        <div className="relative md:max-h-[390px] mb-1 md:mb-5 overflow-hidden content-end">
                          {show.recommended && (
                            <div className="absolute top-2 left-2 py-0 px-2 bg-off-white">
                              <small>Trending</small>
                            </div>
                          )}
                          <img
                            className="min-w-full m-auto"
                            src={show.image_urls[0]}
                            alt="showrunner"
                          />
                        </div>
                      </Link>
                    )}
                    <Link href="/show/[id]" to={`/show/${show.show_id}`}>
                      <h1 className="text-md md:text-xl my-1">{show.display_title}</h1>
                    </Link>
                    <p className="text-secondary-red text-xs md:text-[0.90rem] leading-normal">
                      {show.top_tags.join(' | ')}
                    </p>

                    <div className="flex justify-between text-xs md:text-sm my-2 sm:my-4 items-center font-normal">
                      <div className="flex flex-1 flex-wrap capitalize">
                        <div className="flex mr-4 py-1 items-center">
                          <MapPinIcon className="w-5" /> {show.venues?.venue_name}
                        </div>
                        {show.recommended ? (
                          <div className="flex mr-4 py-1 items-center">
                            <BoltIcon className="w-5" /> Trending
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="flex mr-4 py-1 items-center">
                          <CalendarIcon className="w-5 mr-1" /> {formatDate(show.start_date, true)}-{' '}
                          {formatDate(show.end_date, true)}
                        </div>
                      </div>
                      {isOwner && (
                        <div
                          className="flex-none cursor-pointer items-center"
                          onClick={() => removeShow(list.id, show.show_id)}
                        >
                          <TrashIcon className="w-5 text-dark-red" />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>

        {/* Map */}
        {(isWide || showMobileMap) && (
          <div className="flex relative w-full sm:w-[50%] h-full max-w-[90rem] md:p-0 justify-center mx-auto">
            {list && (
              <ShowrunnerMap
                mainMapViewState={listMapViewState}
                featureData={featureData}
                mapImageInfo={mapImageInfo}
                mapPopupInfo={mapPopupInfo}
                mapTopTagsInfo={mapTopTagsInfo}
                onPinClick={onPinClick}
                onMove={onMove}
                closeShowMapDialog={closeShowMapDialog}
              />
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SingleListPage;

const MessageFragment = ({ meta, message }) => (
  <Fragment>
    <Meta title={meta} />
    <section className="w-screen h-[100px] overflow-hidden bg-black/90"></section>
    <div className="flex sm:flex-row w-screen mt-2 h-[calc(100vh-100px)] overflow-hidden">
      <h2 className="text-center my-auto mx-auto text-xl">{message}</h2>
    </div>
  </Fragment>
);
