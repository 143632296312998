import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from './../util/auth';
import { PencilIcon } from '@heroicons/react/24/outline';
import { getProfilePhoto, uploadProfilePhoto } from '../util/util';
import { UserCircleIcon } from '@heroicons/react/24/solid';

function ProfilePhoto({ userId, isSettings, small }) {
  const auth = useAuth();
  const [userPhoto, setUserPhoto] = useState();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Fetches the profile photo based on the user id.
   * passed as a prop in the ProfilePhoto component.
   * Only fetches the photo if the user is authenticated.
   */
  const fetchProfilePhoto = useCallback(async () => {
    try {
      const photo = await getProfilePhoto(userId);
      setUserPhoto(photo);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, [userId]);

  useEffect(() => {
    fetchProfilePhoto();
  }, [fetchProfilePhoto]);

  /**
   * Uploads a new profile photo, only allowed
   * if the user is authenticated and the user is
   * the owner of the profile.
   */
  const uploadImage = useCallback(
    async e => {
      setIsLoading(true);
      const file = e.target.files[0];
      try {
        if (auth.user.id) {
          // TODO; loading symbol
          await uploadProfilePhoto(file, auth.user.id);
          // TODO: success confirmation msg
          fetchProfilePhoto();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [auth.user.id, fetchProfilePhoto]
  );

  return (
    <div className="w-fit">
      <div className="relative w-fit">
        {userPhoto ? (
          <div className="avatar mx-auto my-auto">
            <div className={`${small ? 'w-8 md:w-10' : 'w-36 md:w-44'} rounded-full`}>
              <img src={userPhoto} alt="avatar" />
            </div>
          </div>
        ) : (
          <UserCircleIcon className={`${small ? 'w-8 md:w-10' : 'w-[200px] h-[200px]'}`} />
        )}
        {isSettings && (
          <>
            <label htmlFor="photo" className="absolute bottom-1 right-1">
              <div className="flex justify-center items-center bg-secondary-red rounded-full w-10 h-10 md:w-[70px] md:h-[70px]">
                {isLoading ? (
                  <span className="loading loading-spinner loading-lg text-white"></span>
                ) : (
                  <PencilIcon className="text-white w-5 h-5 md:w-8 md:h-8" />
                )}
              </div>
            </label>
            <input
              style={{ display: 'none' }}
              id="photo"
              type="file"
              onChange={e => uploadImage(e)}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default ProfilePhoto;
