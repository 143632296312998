import NADA_LOGO_FAIR from '../assets/images/nada.png';
import INDEPENDENT_LOGO_FAIR from '../assets/images/independent.png';
import FUTURE_FAIR_LOGO from '../assets/images/future_fair.png';
import UNTITLED_FAIR_LOGO from '../assets/images/untitled.png';

export const MEDIUM_TAGS = [
  'All Art',
  'Paintings',
  'Videos',
  'Multimedia',
  'Photography',
  'Sculptures',
  'Drawings',
  'Installation',
];

export const TOP_TAGS = [
  'Black Experience',
  'Still Life',
  'Pop Art',
  'Street Art',
  'Cartoons',
  'Sexuality',
  'Feminism',
  'Material Experimentation',
  'Mysticism',
  'Portraits',
  'Realism',
  'Nature',
  'Societal Critique',
  'Food',
  'Architecture',
  'Collage',
  'Animals',
  'Found Objects',
  'Geometry',
  'Immigrant',
  'Interior Spaces',
  'Motherhood',
  'Surrealism',
  'The Gaze',
  'Mental Health',
  'LGBTQ',
  'Gender Identity',
  'Otherworldly',
  'Environmentalism',
  'Political Commentary',
  'Mythology',
  'Papier-mâché',
];

export const STYLE_TAGS = ['Figurative', 'Conceptual', 'Landscape', 'Abstract'];

export const ON_VIEW_TAGS = ['On View', 'Opening Soon', 'Closing Soon', 'Opening Today'];

export const LOCATION_TAGS = [
  'Chelsea',
  'Tribeca',
  'Lower East Side',
  'Lower Manhattan',
  'SoHo-NoHo',
  'West Village',
  'East Village',
  'Murray Hill',
  'Midtown',
  'Upper East Side',
  'Upper Manhattan West',
  'North Brooklyn',
  'Central Brooklyn',
  'South Brooklyn',
  'Queens',
  'Bronx',
];

export const INIT_MAP_VIEW_STATE = {
  longitude: parseFloat('-74.004'),
  latitude: parseFloat('40.72'),
  zoom: parseFloat('14'),
};

export const trendingShowsLayer = {
  id: 'trendingShowsLayer',
  type: 'symbol',
  source: 'my-data',
  filter: ['boolean', ['get', 'recommended'], true],
  layout: {
    'icon-image': 'pin-icon',
    'icon-size': 1,
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-color': '#FF8C00', // Color of the marker
  },
  // TO REVIEW
  //paint: {
  //  'circle-color': '#00008b',
  //  'circle-radius': 8,
  //  'circle-stroke-color': '#fff',
  //  'circle-stroke-width': 1,
  //  'circle-opacity': [
  //    'interpolate',
  //    ['linear'],
  //    ['zoom'],
  //    // zoom is 12 (or less)
  //    12,
  //    1,
  //    // zoom is 13 (or greater)
  //    13,
  //    0.8,
  //  ],
  //},
};

export const regularShowsLayer = {
  id: 'regularShowsLayer',
  type: 'symbol',
  source: 'my-data',
  filter: ['==', ['get', 'recommended'], false],
  layout: {
    'icon-image': 'pin-icon-reg',
    'icon-size': 1,
    'icon-allow-overlap': true,
  },
};

export const formatDate = (dateString, showYear) => {
  if (!dateString) return '';
  const [year, month, day] = dateString.split('-');
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (showYear) {
    return `${monthNames[month - 1]} ${day}, ${year}`;
  }
  return `${monthNames[month - 1]} ${day}`;
};

/**
 * Function to return a boolean if the end dates is lower than
 * today's date
 */
export function isPastShow(endDate) {
  const today = new Date().setHours(0, 0, 0, 0);
  const [year, month, day] = endDate.split('-');
  const end = new Date(year, month - 1, day).setHours(0, 0, 0, 0);
  return end < today;
}

/**
 * Function to convert a single time string to 12-hour format with AM/PM
 */
export function formatTime(timeString) {
  const [hour, minute] = timeString.split(':');
  let hourInt = parseInt(hour, 10);
  const period = hourInt >= 12 ? 'p.m.' : 'a.m.';
  hourInt = hourInt % 12 || 12; // Convert to 12-hour format
  return `${hourInt}:${minute} ${period}`;
}

/**
 * Function to format the start and end times
 * @param {*} startTime
 * @param {*} endTime
 * @returns
 */
export function formatTimeRange(startTime, endTime) {
  const formattedStartTime = formatTime(startTime);
  if (!endTime) {
    return formattedStartTime;
  }
  const formattedEndTime = formatTime(endTime);
  return `${formattedStartTime} – ${formattedEndTime}`;
}

/**
 * Function to format a date string to YYYY-MM-DD format
 * @param {*} date
 * @returns
 */
export function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 * Returns a friendly readable joined date string,
 * e.g "Joined in Jan 2020"
 * @param {*} dateString
 * @returns
 */
export function formatJoinDate(dateString) {
  const date = new Date(dateString);
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  return `${month} ${year}`;
}

/**
 * An object to map fair names to their respective logos
 */
export const fairAssets = {
  nada: NADA_LOGO_FAIR,
  independent: INDEPENDENT_LOGO_FAIR,
  future: FUTURE_FAIR_LOGO,
  untitled: UNTITLED_FAIR_LOGO,
};
