import { useEffect, useState, useCallback, Fragment, useRef } from 'react';
import { useFiltersStore, useMapViewStore, useTextSearchStore } from '../stores';
import MultiSelectDropdown from './../components/MultiSelectDropdown';
import MobileFilterCard from './../components/MobileFilterCard';
import HomeFooter from '../components/HomeFooter';
import Content from './../components/Content';
import FilterStats from './../components/FilterStats';
import ShowrunnerMap from './../components/ShowRunnerMap';
import SearchTextMenu from './../components/SearchTextMenu';
import StatefulTextSearch from './../components/StatefulTextSearch';
import HomeDesktopFilter from './HomeDesktopFilter';
import {
  MEDIUM_TAGS,
  TOP_TAGS,
  STYLE_TAGS,
  LOCATION_TAGS,
  ON_VIEW_TAGS,
  trendingShowsLayer,
  regularShowsLayer,
} from '../util/data-factory';
import { useClickAway } from 'react-use';
import { useRouter } from './../util/router';
import { getShows } from '../util/util';
import FairHeader from './FairHeader';
import { fairAssets } from '../util/data-factory';

const Feed = ({ isFair }) => {
  const boxRef = useRef(null);
  const { location, params } = useRouter();
  const [featureData, setFeatureData] = useState();
  const [shows, setShows] = useState([]);
  const [recommendedShows, setRecommendedShows] = useState([]);
  const [error, setError] = useState(null);
  const {
    showListView,
    open,
    selectedMediums,
    selectedLocation,
    selectedSubject,
    selectedStyles,
    sortedByRelevance,
    sortedByRelevanceToggle,
    setShowListView,
    setAllArtMedium,
    addToFilter,
    removeFromFilter,
    resetFilters,
    getCounts,
  } = useFiltersStore();
  const { query, resetTextQuery, isInputOpen } = useTextSearchStore();
  const { mainMapViewState, setMainMapViewState } = useMapViewStore();

  const [currentKey, setCurrentKey] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  // TODO: Use and object with after pasring the data
  const [mapPopupInfo, setMapPopupInfo] = useState();
  const [mapImageInfo, setMapImageInfo] = useState();
  const [mapTopTagsInfo, setMapTopTagsInfo] = useState();

  /**
   * Fetch shows from the database based on the selected filters.
   * Updates when filters change using the useFiltersStore.
   * so filters are persistent, across app. Uses getShows api.
   */
  const fetchShows = useCallback(async () => {
    try {
      const data = await getShows({
        open,
        sortedByRelevance,
        selectedMediums,
        selectedLocation,
        selectedSubject,
        selectedStyles,
        textSearch: query,
        fairName: params.fairname,
      });
      setShows(data);
      const recommendedShows = data.filter(show => show.recommended === true);
      setRecommendedShows(recommendedShows);
    } catch (error) {
      console.error(error);
      setError('Failed to fetch shows');
    }
  }, [
    open,
    sortedByRelevance,
    selectedLocation,
    selectedMediums,
    selectedSubject,
    selectedStyles,
    params.fairname,
    query,
  ]);

  /**
   * Fetch shows when the component mounts.
   * If there is a query, search by query.
   * default is to fetch shows.
   */
  useEffect(() => {
    fetchShows();
  }, [fetchShows]);

  /**
   * Close the current filter dropdown when clicked outside.
   * Uses the useClickAway hook.
   */
  useClickAway(boxRef, () => {
    setCurrentFilter(null);
  });

  /**
   * Format filtered data as features to be
   * read as clusters by map-gl. Updates when filtered
   * data changes.
   */
  useEffect(() => {
    const formatted_data = shows.map(i => {
      let longitude = parseFloat(i.venues.longitude);
      let latitude = parseFloat(i.venues.latitude);
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
        properties: { ...i, lat: latitude, lng: longitude },
      };
    });

    setFeatureData({ type: 'FeatureCollection', features: formatted_data });
  }, [shows]);

  /**
   * Handles logic when a filter is selected.
   * Adds or removes the selected filter from the store,
   * uses Map() object to store the selected filters.
   */
  const handleSelectMedium = medium => {
    if (medium === 'All Art') {
      setAllArtMedium();
      return;
    }
    if (selectedMediums.has(medium)) {
      removeFromFilter('selectedMediums', medium);
    } else {
      addToFilter('selectedMediums', medium);
    }
  };

  const handleSelectLocation = location => {
    if (selectedLocation.has(location)) {
      removeFromFilter('selectedLocation', location);
    } else {
      addToFilter('selectedLocation', location);
    }
  };

  const handleSelectedSubject = subject => {
    if (selectedSubject.has(subject)) {
      removeFromFilter('selectedSubject', subject);
    } else {
      addToFilter('selectedSubject', subject);
    }
  };

  const handleSelectStyle = style => {
    if (selectedStyles.has(style)) {
      removeFromFilter('selectedStyles', style);
    } else {
      addToFilter('selectedStyles', style);
    }
  };

  const handleOnView = onView => {
    if (open.has(onView)) {
      removeFromFilter('open', onView);
    } else {
      addToFilter('open', onView);
    }
  };

  /**
   * Get the current counts of the selected filters.
   * Uses the getCounts function from the useFiltersStore.
   */
  const counts = getCounts();
  const filterMap = {
    MEDIUM: {
      options: MEDIUM_TAGS,
      selected: selectedMediums,
    },
    'ON VIEW': {
      options: ON_VIEW_TAGS,
      selected: open,
    },
    NEIGHBORHOOD: {
      options: LOCATION_TAGS,
      selected: selectedLocation,
    },
    SUBJECT: {
      options: TOP_TAGS,
      selected: selectedSubject,
    },
    STYLE: {
      options: STYLE_TAGS,
      selected: selectedStyles,
    },
  };

  const handleFilterClick = filter => {
    if (currentKey === filter) {
      setCurrentKey(null);
      setCurrentFilter(null);
      return;
    }
    setCurrentKey(filter);
    setCurrentFilter(filterMap[filter]);
  };

  // TODO: Handle error
  //if (error) {
  //  return <div>Error: {error}</div>;
  //}

  /**
   * Handles logic when click event on cluster and markers
   */
  const onPinClick = useCallback((event, mapRef) => {
    const feature = event.features && event.features[0];
    if (!feature) return;
    const layer = feature.layer;

    if (layer.id === trendingShowsLayer.id || layer.id === regularShowsLayer.id) {
      //const mapboxSource = mapRef.current.getSource('my-data');
      //console.debug(mapboxSource);
      //console.log(feature.properties);
      event.originalEvent.stopPropagation();
      setMapPopupInfo({ ...feature.properties, venues: JSON.parse(feature.properties.venues) });
      setMapImageInfo(JSON.parse(feature.properties.image_urls));
      setMapTopTagsInfo(JSON.parse(feature.properties.top_tags));
      if (mapRef.current) {
        mapRef.current.flyTo({
          center: [feature.properties.lng, feature.properties.lat],
          essential: true, // Instant
          curve: 1.5, // Smooth animation
          duration: 1000, // Transition duration
          padding: {
            top: 0,
            bottom: 300,
            left: 150,
            right: 0,
          },
        });
      }
    } else {
      event.originalEvent.stopPropagation();
    }
  }, []);

  /**
   * Handles logic when moving on the map, triggers on
   * zooming and panning.
   */
  const onMove = useCallback(
    event => {
      const { latitude, longitude, zoom } = event.viewState;
      setMainMapViewState({ latitude, longitude, zoom });
    },
    [setMainMapViewState]
  );

  const closeShowMapDialog = () => {
    setMapPopupInfo(null);
    setMapImageInfo(null);
    setMapTopTagsInfo(null);
  };

  const atFair = fairAssets[params.fairname];

  return (
    <Fragment>
      {showListView ? (
        <div className="relative">
          {isFair ? (
            <FairHeader fairname={params.fairname} />
          ) : (
            <HomeDesktopFilter
              selectedMediums={selectedMediums}
              handleSelectMedium={handleSelectMedium}
            />
          )}
          <div className="sticky top-[calc(100vh-110px)] left-[calc(50vw-80px)] w-40 text-center z-40">
            <button
              className="btn sm:btn-sm md:btn-md bg-secondary-red rounded-3xl"
              onClick={() => setShowListView(false)}
            >
              Show Map
            </button>
          </div>

          {/* sub filters Mobile */}
          <div className="sticky top-0 w-screen lg:hidden py-1 px-2 bg-[#f9f9f9] z-20">
            {isInputOpen ? (
              <div className="p-1">
                <StatefulTextSearch />
              </div>
            ) : (
              <div className="grid grid-flow-col auto-cols-max py-3 gap-4 overflow-auto no-scrollbar">
                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.selectedMediumsCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.selectedMediumsCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('MEDIUM')}>
                    MEDIUM
                  </button>
                </div>
                {!isFair && (
                  <Fragment>
                    <div className="indicator">
                      <span
                        className={`indicator-item badge bg-secondary-red text-white ${
                          counts.openCount === 0 && 'hidden'
                        }`}
                      >
                        {counts.openCount}
                      </span>
                      <button className="btn btn-md" onClick={() => handleFilterClick('ON VIEW')}>
                        OPENING
                      </button>
                    </div>

                    <div className="indicator">
                      <span
                        className={`indicator-item badge bg-secondary-red text-white ${
                          counts.selectedLocationCount === 0 && 'hidden'
                        }`}
                      >
                        {counts.selectedLocationCount}
                      </span>
                      <button
                        className="btn btn-md"
                        onClick={() => handleFilterClick('NEIGHBORHOOD')}
                      >
                        NEIGHBORHOOD
                      </button>
                    </div>
                  </Fragment>
                )}
                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.selectedSubjectCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.selectedSubjectCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('SUBJECT')}>
                    SUBJECT
                  </button>
                </div>
                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.selectedStylesCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.selectedStylesCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('STYLE')}>
                    STYLE
                  </button>
                </div>
                <button
                  className={`btn btn-sm btn-link px-0 m-0 my-auto no-underline ${
                    sortedByRelevance ? 'text-secondary-red' : 'text-black'
                  }`}
                  onClick={() => sortedByRelevanceToggle()}
                >
                  {sortedByRelevance ? 'SORTED BY TRENDING' : 'SORT BY: TRENDING'}
                </button>
              </div>
            )}

            {currentFilter && (
              <MobileFilterCard
                ref={boxRef}
                currentKey={currentKey}
                filterMap={filterMap}
                options={currentFilter.options}
              />
            )}
          </div>

          {/* sub filters Desktop */}
          <div className="sticky hidden top-0 lg:flex flex-wrap justify-center items-center text-base pt-0 lg:py-4 bg-[#f1f5f9] z-20">
            {(location.pathname === '/' || atFair) && <SearchTextMenu postion="dropdown-start" />}
            <button
              className={`btn btn-md btn-link mr-2 no-underline ${
                sortedByRelevance ? 'text-secondary-red' : 'text-black'
              }`}
              onClick={() => sortedByRelevanceToggle()}
            >
              {sortedByRelevance ? 'SORTED BY TRENDING' : 'SORT BY: TRENDING'}
            </button>

            <MultiSelectDropdown
              label="Medium"
              tags={MEDIUM_TAGS}
              selectedTags={selectedMediums}
              count={counts.selectedMediumsCount}
              handleSelect={handleSelectMedium}
            />
            {!isFair && (
              <Fragment>
                <MultiSelectDropdown
                  label="Opening"
                  tags={ON_VIEW_TAGS}
                  selectedTags={open}
                  count={counts.openCount}
                  handleSelect={handleOnView}
                />
                <MultiSelectDropdown
                  label="Neighborhood"
                  tags={LOCATION_TAGS}
                  selectedTags={selectedLocation}
                  count={counts.selectedLocationCount}
                  handleSelect={handleSelectLocation}
                />
              </Fragment>
            )}
            <MultiSelectDropdown
              label="Subject"
              tags={TOP_TAGS}
              selectedTags={selectedSubject}
              count={counts.selectedSubjectCount}
              handleSelect={handleSelectedSubject}
            />
            <MultiSelectDropdown
              label="style"
              tags={STYLE_TAGS}
              selectedTags={selectedStyles}
              count={counts.selectedStylesCount}
              handleSelect={handleSelectStyle}
            />
          </div>

          {/* Search Stats */}
          <div className="sticky top-16 z-10">
            <FilterStats
              shows={shows}
              recommendedShows={recommendedShows}
              resetFilters={() => {
                resetFilters();
                resetTextQuery();
              }}
            />
          </div>

          {/* content */}
          <Content shows={shows} />

          <HomeFooter hideMobile={true} sticky />
        </div>
      ) : (
        <div className="w-screen h-auto">
          {/* Map View */}
          <div className="flex w-screen h-auto lg:hidden px-2 bg-transparent pt-14">
            {/* sub filters Mobile */}
            {isInputOpen ? (
              <div className="p-1 w-full">
                <StatefulTextSearch />
              </div>
            ) : (
              <div className="grid grid-flow-col auto-cols-max m-auto py-3 gap-4 overflow-auto no-scrollbar">
                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.selectedMediumsCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.selectedMediumsCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('MEDIUM')}>
                    MEDIUM
                  </button>
                </div>
                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.openCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.openCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('ON VIEW')}>
                    OPENING
                  </button>
                </div>
                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.selectedLocationCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.selectedLocationCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('NEIGHBORHOOD')}>
                    NEIGHBORHOOD
                  </button>
                </div>
                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.selectedSubjectCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.selectedSubjectCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('SUBJECT')}>
                    SUBJECT
                  </button>
                </div>
                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.selectedStylesCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.selectedStylesCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('STYLE')}>
                    STYLE
                  </button>
                </div>
              </div>
            )}

            {currentFilter && (
              <MobileFilterCard
                ref={boxRef}
                currentKey={currentKey}
                filterMap={filterMap}
                options={currentFilter.options}
                floating={true}
              />
            )}
          </div>
          <div className="hidden h-[15vh] lg:flex flex-wrap justify-center items-center text-base pt-20">
            {/* sub filters Desktop */}
            {location.pathname === '/' && <SearchTextMenu postion="dropdown-start" />}
            <MultiSelectDropdown
              label="Medium"
              tags={MEDIUM_TAGS}
              selectedTags={selectedMediums}
              count={counts.selectedMediumsCount}
              handleSelect={handleSelectMedium}
            />
            <MultiSelectDropdown
              label="Opening"
              tags={ON_VIEW_TAGS}
              selectedTags={open}
              count={counts.openCount}
              handleSelect={handleOnView}
            />
            <MultiSelectDropdown
              label="Neighborhood"
              tags={LOCATION_TAGS}
              selectedTags={selectedLocation}
              count={counts.selectedLocationCount}
              handleSelect={handleSelectLocation}
            />
            <MultiSelectDropdown
              label="Subject"
              tags={TOP_TAGS}
              selectedTags={selectedSubject}
              count={counts.selectedSubjectCount}
              handleSelect={handleSelectedSubject}
            />
            <MultiSelectDropdown
              label="style"
              tags={STYLE_TAGS}
              selectedTags={selectedStyles}
              count={counts.selectedStylesCount}
              handleSelect={handleSelectStyle}
            />
          </div>
          <div className="relative h-[82vh] md:h-[80vh]">
            {/* Search Stats */}
            <div className="absolute z-[2] left-0 w-full">
              <FilterStats
                shows={shows}
                recommendedShows={recommendedShows}
                resetFilters={() => {
                  resetFilters();
                  resetTextQuery();
                }}
              />
            </div>
            <div className="sticky top-[calc(100vh-110px)] left-[calc(50vw-80px)] w-40 z-40 text-center">
              <button
                className="btn sm:btn-sm md:btn-md bg-secondary-red rounded-3xl"
                onClick={() => setShowListView(true)}
              >
                Show List
              </button>
            </div>

            <ShowrunnerMap
              mainMapViewState={mainMapViewState}
              featureData={featureData}
              mapImageInfo={mapImageInfo}
              mapPopupInfo={mapPopupInfo}
              mapTopTagsInfo={mapTopTagsInfo}
              onPinClick={onPinClick}
              onMove={onMove}
              closeShowMapDialog={closeShowMapDialog}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Feed;

// Other Map styles
// mapStyle="https://raw.githubusercontent.com/go2garret/maps/main/src/assets/json/openStreetMap.json" // https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json
// mapStyle="https://external.xx.fbcdn.net/maps/vt/style/grayscale/"
