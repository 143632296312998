import React from 'react';

const HomeDesktopFilter = ({ selectedMediums, handleSelectMedium }) => {
  return (
    <div className="mt-2 md:mt-20 lg:mt-28">
      <div
        className="hidden lg:flex text-6xl leading-tight tracking-0.25 max-w-5xl justify-center flex-wrap gap-x-12 uppercase"
        style={{ margin: '0 auto' }}
      >
        <div
          className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
            selectedMediums.size === 0 ? 'opacity-100' : 'opacity-25'
          }`}
        >
          <div className="text-black font-medium" onClick={() => handleSelectMedium('All Art')}>
            All Art
          </div>
        </div>
        <div
          className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
            selectedMediums.has('Paintings') ? 'opacity-100' : 'opacity-25'
          }`}
        >
          <div className="text-black font-medium" onClick={() => handleSelectMedium('Paintings')}>
            Paintings
          </div>
        </div>
        <div
          className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
            selectedMediums.has('Videos') ? 'opacity-100' : 'opacity-25'
          }`}
        >
          <div
            className="text-center text-black font-medium"
            onClick={() => handleSelectMedium('Videos')}
          >
            Videos
          </div>
        </div>
        <div
          className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
            selectedMediums.has('Multimedia') ? 'opacity-100' : 'opacity-25'
          }`}
        >
          <div className="text-black font-medium" onClick={() => handleSelectMedium('Multimedia')}>
            Multimedia
          </div>
        </div>
        <div
          className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
            selectedMediums.has('Photography') ? 'opacity-100' : 'opacity-25'
          }`}
        >
          <div className="text-black font-medium" onClick={() => handleSelectMedium('Photography')}>
            Photography
          </div>
        </div>
        <div
          className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
            selectedMediums.has('Sculptures') ? 'opacity-100' : 'opacity-25'
          }`}
        >
          <div className="text-black font-medium" onClick={() => handleSelectMedium('Sculptures')}>
            Sculpture
          </div>
        </div>
        <div
          className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
            selectedMediums.has('Drawings') ? 'opacity-100' : 'opacity-25'
          }`}
        >
          <div className="text-black font-medium" onClick={() => handleSelectMedium('Drawings')}>
            Drawings
          </div>
        </div>
        <div
          className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
            selectedMediums.has('Installation') ? 'opacity-100' : 'opacity-25'
          }`}
        >
          <div
            className="text-black font-medium"
            onClick={() => handleSelectMedium('Installation')}
          >
            Installations
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDesktopFilter;
