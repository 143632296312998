import { Fragment } from 'react';
import Meta from './../components/Meta';
import Feed from './../components/Feed';

function FairPage() {
  return (
    <Fragment>
      <Meta title="Fair Feed" />
      <Feed isFair={true} />
    </Fragment>
  );
}

export default FairPage;
