import { Fragment } from 'react';
import Meta from './../components/Meta';
import Feed from './../components/Feed';

function IndexPage() {
  return (
    <Fragment>
      <Meta title="Showrunner Home Feed" />
      <Feed />
    </Fragment>
  );
}

export default IndexPage;
