import { create } from 'zustand';

const useFiltersStore = create((set, get) => ({
  query: '',
  rawQuery: '',
  isInputOpen: false,
  setQuery: query => set({ query }),
  setRawQuery: rawQuery => set({ rawQuery }),
  resetTextQuery: () => set({ query: '', rawQuery: '' }),
  setIsInputOpen: isInputOpen => set({ isInputOpen: isInputOpen }),
}));

export default useFiltersStore;
