import { useEffect, useCallback, useState, Fragment } from 'react';
import Meta from '../components/Meta';
import { getListsByOwner } from '../util/util';
import { Link } from '../util/router';
import { requireAuth } from '../util/auth';
import { useAuth } from '../util/auth';
import { LockOpenIcon, LockClosedIcon, TrashIcon } from '@heroicons/react/24/outline';
import DeleteListModal from '../components/DeleteListModal';
import HomeFooter from '../components/HomeFooter';
import IMG_PLACEHOLDER from '../assets/images/placeholder1.png';

function MyListsPage() {
  const auth = useAuth();
  const [userLists, setUserLists] = useState([]);
  const [hoveredListId, setHoveredListId] = useState(null);
  const [removingItem, setRemovingItem] = useState(false);
  const [listId, setListId] = useState(null);

  const fetchUserLists = useCallback(async () => {
    try {
      if (auth.user) {
        const lists = await getListsByOwner(auth.user.id);
        setUserLists(lists);
      }
    } catch (error) {
      console.error(error);
    }
  }, [auth.user]);

  useEffect(() => {
    fetchUserLists();
  }, [auth.user, fetchUserLists]);

  const handleRemoveList = listId => {
    setRemovingItem(true);
    setListId(listId);
  };

  const onConfirm = () => {
    setRemovingItem(false);
    fetchUserLists();
  };

  return (
    <Fragment>
      <Meta title="My Lists" />
      <section className="w-screen h-[150px] overflow-hidden bg-white">
        <div className="flex w-full h-full max-w-[90rem] p-4 md:p-8 my-auto mx-auto">
          <div className="content-end">
            <h1 className="text-3xl md:text-3xl text-slate-600">My Lists</h1>
          </div>
        </div>
      </section>

      <section className="w-screen min-h-[calc(100vh-190px)] mt-10">
        <div className="flex w-full max-w-[90rem] p-3 md:p-8 justify-center mx-auto">
          {userLists.length ? (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-5 md:gap-8 w-full">
              {userLists.map(list => (
                <div
                  key={list.id}
                  className="relative w-full min-h-[260px] md:min-h-[290px] max-w-[11rem] md:max-w-[19rem] cursor-pointer justify-self-center"
                  onMouseEnter={() => setHoveredListId(list.id)}
                  onMouseLeave={() => setHoveredListId(null)}
                >
                  <div
                    className={`absolute top-2 right-2 z-10 w-7 h-7 p-1 opacity-80 bg-white rounded-xl hover:scale-[1.025] hover:opacity-100 ${
                      hoveredListId === list.id || window.innerWidth < 768 ? 'block' : 'hidden'
                    }`}
                    onClick={() => handleRemoveList(list.id)}
                  >
                    <TrashIcon className="w-5 text-dark-red" />
                  </div>
                  <Link href="/lists/[id]" to={`/lists/${list.id}`}>
                    <div className="w-auto h-4/5 rounded-lg shadow-xl overflow-hidden relative">
                      <img
                        className="absolute top-0 left-0 w-full h-full object-cover"
                        alt="list_image"
                        src={
                          list.first_show_data?.image_urls[0]
                            ? list.first_show_data.image_urls[0]
                            : IMG_PLACEHOLDER
                        }
                      />
                    </div>
                  </Link>

                  <div className="w-full h-1/5">
                    <div className="mt-2 text-lg md:text-xl text-slate-900 uppercase whitespace-nowrap overflow-hidden text-ellipsis">
                      {list.list_name}
                    </div>
                    <div className="flex justify-between">
                      <p className="text-slate-400 text-md">{list.shows.length} saved</p>
                      {list.is_private ? (
                        <LockClosedIcon className="w-4" />
                      ) : (
                        <LockOpenIcon className="w-4" />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center text-lg md:text-xl text-slate-900 mt-10 ">
              Seems like you don't have any saved lists yet.
            </div>
          )}
        </div>
      </section>
      {removingItem && <DeleteListModal listId={listId} onDone={onConfirm} />}
      <HomeFooter hideMobile sticky />
    </Fragment>
  );
}

export default requireAuth(MyListsPage);
