import React from 'react';
import { fairAssets } from '../util/data-factory';

function FairHeader({ fairname }) {
  const fairLogo = fairAssets[fairname];

  return (
    <div className="flex max-w-5xl justify-center flex-wrap mx-auto h-40 md:h-60 py-10">
      {fairLogo && <img src={fairLogo} alt="_" className="mt-20" />}
    </div>
  );
}

export default FairHeader;
