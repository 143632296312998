import React from 'react';
import Meta from './../components/Meta';
import Profile from './../components/Profile';
import { useRouter } from './../util/router';
import { requireAuth } from './../util/auth';

function ProfilePage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Profile" />
      <Profile section={router.query.section} key={router.query.section} />
    </>
  );
}

export default requireAuth(ProfilePage);
