import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from './../util/auth';
import ProfilePhoto from './ProfilePhoto';

function SettingsGeneral(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    // Make the username lowercase
    data.username = data.username.toLowerCase();

    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: 'success',
          message: 'Your profile has been updated',
        });
      })
      .catch(error => {
        if (error.code === 'auth/requires-recent-login') {
          props.onStatus({
            type: 'requires-recent-login',
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else if (error.code === '23505') {
          props.onStatus({
            type: 'error',
            message: 'Sorry, that username is taken. Please try another one.',
          });
        } else if (error.code === '23514') {
          props.onStatus({
            type: 'error',
            message: 'Username must be less than 30 characters and use only a-z, A-Z, 0-9, ., or _',
          });
        } else {
          // Set error status
          props.onStatus({
            type: 'error',
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <div className="p-2 md:p-8">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-3xl md:text-4xl font-semibold leading-7">My Account</h2>
          <p className="mt-1 text-lg leading-6 text-slate-500">
            Edit my contact info and personal details.
          </p>

          <div className="mt-10 md:flex justify-between items-start">
            <ProfilePhoto userId={auth.user.id} isSettings={true} />
            <div className="md:[width:calc(100%-430px)]">
              <label htmlFor="name" className="block text-lg font-medium leading-6">
                Name *
              </label>
              <div className="">
                <input
                  className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 mt-1"
                  name="name"
                  type="text"
                  placeholder="Name"
                  defaultValue={auth.user.name}
                  {...register('name', { required: 'Please enter your name' })}
                />
                {errors.name && (
                  <p className="mt-1 text-lg text-left text-red-600">{errors.name.message}</p>
                )}
              </div>
              <label htmlFor="username" className="mt-2 block text-lg font-medium leading-6">
                Username *
              </label>
              <div className="">
                <input
                  className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 mt-1"
                  name="username"
                  type="text"
                  placeholder="Username"
                  defaultValue={auth.user.username}
                  {...register('username', { required: 'Please enter your username' })}
                />
                {errors.username && (
                  <p className="mt-1 text-lg text-left text-red-600">{errors.username.message}</p>
                )}
              </div>
              <label htmlFor="email" className="mt-2 block text-lg font-medium leading-6">
                Email address *
              </label>
              <div className="">
                <input
                  className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 mt-1"
                  name="email"
                  type="email"
                  placeholder="Email"
                  defaultValue={auth.user.email}
                  {...register('email', { required: 'Please enter your email' })}
                />
                {errors.email && (
                  <p className="mt-1 text-lg text-left text-red-600">{errors.email.message}</p>
                )}
              </div>
            </div>
          </div>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <label htmlFor="about" className="block text-lg font-medium leading-6">
                Bio
              </label>
              <div className="mt-2">
                <textarea
                  className="block w-full rounded-md border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 mt-1 p-3"
                  id="about"
                  name="about"
                  rows={3}
                  defaultValue={auth.user.about}
                  {...register('about')}
                  placeholder="Write a few sentences about yourself."
                />
              </div>
            </div>
          </div>

          <div className="mt-3 flex items-center justify-end gap-x-6">
            <button
              type="submit"
              className="rounded-md bg-secondary-red px-10 py-2 text-lg text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2"
              disabled={pending}
            >
              {pending ? '...' : 'Save'}
            </button>
          </div>
        </div>
      </form>
      <PasswordUpdate onStatus={props.onStatus} />
    </div>
  );
}

export default SettingsGeneral;

const PasswordUpdate = props => {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  const onSubmit = data => {
    // Show pending indicator
    setPending(true);

    auth
      .updatePassword(data.pass)
      .then(() => {
        // Clear form
        reset();
        // Set success status
        props.onStatus({
          type: 'success',
          message: 'Your password has been updated',
        });
      })
      .catch(error => {
        if (error.code === 'auth/requires-recent-login') {
          // Update state to show re-authentication modal
          props.onStatus({
            type: 'requires-recent-login',
            // Resubmit after reauth flow
            callback: () => onSubmit({ pass: data.pass }),
          });
        } else {
          // Set error status
          props.onStatus({
            type: 'error',
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <div>
            Password
            <input
              className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 mt-1"
              name="pass"
              type="password"
              placeholder="Password"
              autoComplete="new-password"
              {...register('pass', { required: 'Please enter a password' })}
            />
            {errors.pass && (
              <p className="mt-1 text-sm text-left text-red-600">{errors.pass.message}</p>
            )}
          </div>
        </div>
        <div className="sm:col-span-3">
          <div className="">
            <div className="flex justify-between">Confirm New Password</div>

            <input
              className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 mt-1"
              name="confirmPass"
              type="password"
              autoComplete="new-password"
              placeholder="Confirm Password"
              {...register('confirmPass', {
                required: 'Please enter your new password again',
                validate: value => {
                  if (value === getValues().pass) {
                    return true;
                  } else {
                    return 'Passwords do not match';
                  }
                },
              })}
            />
            {errors.confirmPass && (
              <p className="mt-1 text-sm text-left text-red-600">{errors.confirmPass.message}</p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-end">
        <button
          type="submit"
          className="rounded-md bg-secondary-red px-10 py-2 text-lg text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2"
          disabled={pending}
        >
          {pending ? '...' : 'Update Password'}
        </button>
      </div>
    </form>
  );
};
