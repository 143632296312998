import { useState, useEffect } from 'react';

const detectBrowser = () => {
  const userAgent = navigator.userAgent;

  if (/chrome|crios|crmo/i.test(userAgent)) {
    return 'Chrome';
  } else if (/firefox|fxios/i.test(userAgent)) {
    return 'Firefox';
  } else if (/safari/i.test(userAgent)) {
    return 'Safari';
  } else if (/opr\//i.test(userAgent)) {
    return 'Opera';
  } else if (/edg/i.test(userAgent)) {
    return 'Edge';
  } else if (/msie|trident/i.test(userAgent)) {
    return 'Internet Explorer';
  } else {
    return 'Unknown';
  }
};

const useBrowserType = () => {
  const [browser, setBrowser] = useState('Unknown');

  useEffect(() => {
    setBrowser(detectBrowser());
  }, []);

  return browser;
};

export default useBrowserType;
